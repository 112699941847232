/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImgOrigem from '../assets/images/lp/origem/origem.png'
import ImgOrigemBertoni from '../assets/images/lp/origem/menina-origem.png'
import ImgNomeBertoni from '../assets/images/lp/origem/nome-bertoni.png'
import ImgSucessoBertoni from '../assets/images/lp/origem/sucesso-bertoni.png'

import { 
  cta1,
  cta2,
  cta3,
  cta4,
  cta5,
  cta6,
  cta7,
  imgBox,
  nomeBertoni,
  boxContent,
  DDesktop,
  DMobile
}  from '../assets/styles/SobreContent.styles'

const OrigemPage = () => {
  return (
    <Fragment>
      <div>
        <img css={DMobile} src={ImgOrigem} alt='/' style={{ width: '100%' }} />
      </div>
      <section css={cta1}>
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div css={boxContent}>
                <h2>Já se passou uma década e meia desde que quatro professores decidiram transformar a sociedade por meio da Educação.</h2>
                <p>Foi assim que nasceu o Colégio Bertoni. Conscientes do potencial do Paraná e de Foz do Iguaçu como polo educacional em ascensão, decidiram investir naquele que viria a ser o grande projeto de suas vidas: uma escola de excelência que fosse referência não apenas regional, mas internacional.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]}>
          <img src={ImgOrigem} alt='/' />
        </div>
      </section>
      <section css={cta2}>
        <Container>
          <Row>
            <Col lg={6} sm={12} data-aos-delay={"400"} data-aos="fade-right">
              <img src={ImgNomeBertoni} alt='/' />
            </Col>
            <Col lg={6} sm={12} css={nomeBertoni} data-aos-delay={"400"} data-aos="fade-left">
              <div css={boxContent}>
                <p>O nome do colégio foi escolhido em homenagem a vida e obra de Moisés Santiago Bertoni, renomado naturalista e pesquisador suíço que impactou para sempre a realidade de toda a região da Tríplice Fronteira. Desde o seu início, o Colégio Bertoni adotou abordagem visionária, dedicada a inspirar alunos, pais e a comunidade a perceberem a educação como uma jornada de crescimento</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={cta4} data-aos="zoom-in" data-aos-delay={"450"}>
        <Container>
          <Row>
            <Col lg={12} sm={12}>
              <h4>O tempo e os resultados mantiveram o Colégio Bertoni na vanguarda da educação. </h4>
            </Col>
          </Row>
        </Container>
      </section>
      <div>
        <img css={DMobile} src={ImgOrigemBertoni} alt='/' style={{ width: '100%' }} />
      </div>
      <section css={cta5}>
        <Container>
          <Row>
            <Col lg={6} sm={12} data-aos-delay={"500"} data-aos="fade-right">
              <div css={boxContent}>
                <p>Mas nunca houve limites para aperfeiçoar os métodos de ensino, a marca Bertoni se conectou a experiências vividas em países como Finlândia, Portugal, Estados Unidos, Japão e Cingapura. Conhecer essas experiências in loco e estar sempre a par das melhores práticas de educação em todo o mundo permitiram ao colégio desenvolver uma abordagem inigualável no país.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]} data-aos-delay={"550"} data-aos="fade-left">
          <img src={ImgOrigemBertoni} alt='/' />
        </div>
      </section>
      <section css={cta6}>
        <Container>
          <Row>
            <Col lg={6} sm={12} data-aos-delay={"600"} data-aos="fade-right">
              <img src={ImgSucessoBertoni} alt='/' />
            </Col>
            <Col lg={6} sm={12} css={nomeBertoni} data-aos-delay={"600"} data-aos="fade-left">
              <div css={boxContent}>
                <p>O sucesso do Bertoni, amplamente reconhecido como um dos 50 melhores do país, impulsionou a abertura de unidades em diversas localidades, como Foz, Medianeira, Cascavel, Porto Alegre e Ciudad del Este, no Paraguai.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={cta7}>
        <Container>
          <Row>
            <Col lg={12} sm={12} data-aos="fade-up" data-aos-delay={"650"}>
              <p>Ao se expandir e atender um público cada vez mais diversificado, as escolas passaram a segmentar seus nichos de atuação e a partir de 2023, foram criadas três bandeiras para as oito unidades do grupo: <span>Bert International Schools</span>, conhecida por seu currículo global, conquistas em competições internacionais e aprovações em prestigiadas universidades estrangeiras; <span>Bertoni Pro</span>, com histórico impressionante de aprovações nos principais vestibulares do país; e <span>Bertoni Elos</span>, que se destaca por sua metodologia alinhada aos desafios atuais, baseada em valores sólidos e excelência nas práticas educacionais.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default OrigemPage;
